var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.t('Mẫu hóa đơn')))]),_c('crud-buttons',{attrs:{"name":_vm.t('Mẫu hóa đơn'),"selected-rows":_vm.selectedRows,"modal":"modal-invoiceTemplate"},on:{"on-delete":_vm.onDelete,"on-add":_vm.resetItem}})],1),_c('b-card-body',[_c('b-row',[_c('b-col',[_c('b-form-input',{attrs:{"placeholder":_vm.t('Tìm kiếm'),"debounce":"1000"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"style-class":"vgt-table striped bordered","mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: false,
            },"select-options":{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: _vm.serverParams.perPage,
            }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-preview-invoice-template",modifiers:{"modal-preview-invoice-template":true}}],staticClass:"font-weight-bold",on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field === 'invoiceTemplate')?_c('span',[_c('b-img',{attrs:{"src":props.row.invoiceTemplate,"height":"80px"}})],1):(props.column.field === 'default')?_c('span',[_c('b-badge',{staticClass:"font-small-1",attrs:{"variant":props.row.default ? 'light-primary' : 'light-danger'}},[_c('span',[_vm._v(_vm._s(props.row.default ? "Có" : "Không"))])])],1):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":_vm.t('mẫu hóa đơn'),"modal":"modal-invoiceTemplate"},on:{"on-delete":function($event){return _vm.onDelete(props.row)},"on-edit":function($event){return _vm.onEditItem(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('Hiển thị tối đa'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50', '100', '500']},on:{"input":(value) =>
                        props.perPageChanged({ currentPerPage: value })},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('trên tổng số'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.t('kết quả'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.serverParams.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":(value) => props.pageChanged({ currentPage: value })},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)],1)],1)],1)],1),_c('invoiceTemplate-modal',{attrs:{"item":_vm.item},on:{"refetch-data":_vm.refetchInvoiceTemplates}}),_c('invoice-template-preview-modal',{attrs:{"item":_vm.item}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }